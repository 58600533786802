import React from 'react';
import '../../assets/styles/EmailSelector.scss'
import { Autocomplete, Chip, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

const ContactEmailSelector = ({ users, onChange, dropdownList }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Autocomplete
      id="email-recipients"
      multiple
      freeSolo
      options={dropdownList || []}
      getOptionLabel={(option) => option}
      filterSelectedOptions
      value={users}
      renderTags={(value, getRecipientProps) =>
        value.map((option, index) => (
          <Chip
            {...getRecipientProps({ index })}
            variant="outlined"
            label={option}
            sx={{
              fontSize: '12px',
              backgroundColor: 'antiquewhite'
            }}
          />
        ))
      }
      onChange={(event, newValue) => {
        if (newValue.length <= 10) {
          onChange(newValue);
        } else {
          enqueueSnackbar('You can add upto 10 emails.', {
            variant: 'error',
          });
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, maxLength: 60 }}
            variant="outlined"
            label="Email Recipients"
            placeholder="Select or enter an email"
            margin="normal"
            fullWidth
            required={ users.length > 0 ? false : true }
          />
        );
      }}
    />
  );
};

export default ContactEmailSelector;
