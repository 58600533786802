import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import SiteLink from '../../shared/SiteLink';
import '../../shared/UpgradeDialog.scss';
import PathIcon from '../../assets/icons/path.svg';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import Dialog from '@mui/material/Dialog/Dialog';
import Slide from '@mui/material/Slide/Slide';
import Switch from '@mui/material/Switch';
import { Close as CloseIcon } from '@mui/icons-material';

import { SiteContext } from '../../utils/contexts';
import { withEditorPageTracking } from '../../shared/Tracking';
import { DialogTitle, IconButton } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const prices = {
  monthly: {
    // starterPlus: 8,
    growth: 39,
    premium: 69,
    elite: 129,
    growthOld: '$59',
    premiumOld: '$99',
    eliteOld: '$199',
  },
  yearly: {
    // starterPlus: 60,
    growth: 348,
    premium: 588,
    elite: 1548,
    growthOld: '$589',
    premiumOld: '$999',
    eliteOld: '$1999',
  },
};

function UpgradeDialog({ returnTo, onClose }) {
  const site = useContext(SiteContext);
  const history = useHistory();
  const [schedule, setSchedule] = React.useState('monthly');
  const [isOpen, setIsOpen] = React.useState(true);

  if (site.installed && site.partner === 'shopify') {
    return (window.location.href =
      'https://admin.shopify.com/charges/hellobar-email-popups/pricing_plans');
  }

  const handleClose = (event) => {
    event.preventDefault();
    if (onClose) {
      setIsOpen(false);
      onClose();
    } else {
      returnTo ? history.push(returnTo) : history.goBack();
    }
  };

  const handleChange = () => {
    setSchedule(schedule === 'monthly' ? 'yearly' : 'monthly');
  };

  const handleChangeYearly = () => {
    setSchedule('yearly');
  };

  const handleChangeMonthly = () => {
    setSchedule('monthly');
  };

  const upgradeUrl = (plan) => `/upgrade/${plan}-${schedule}`;

  return (
    <React.Fragment>
      <Dialog
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth={true}
        scroll="body"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="upgrade-account-modal show-modal">
          <DialogTitle component="div" className="dialog-closeable">
            <div className="site-title">
              To unlock the next level of Hello Bar, upgrade your subscription
              for <b>{site.url}</b>.
            </div>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <div className="modal-content">
            <form className="upgrade-account-modal">
              <div className="billing-options">
                <span className="switch-label" onClick={handleChangeMonthly}>
                  Monthly
                </span>
                <Switch
                  onClick={handleChange}
                  checked={schedule === 'yearly'}
                  color="primary"
                />
                <span
                  className="switch-label popular"
                  onClick={handleChangeYearly}
                >
                  Yearly
                </span>
                <img src={PathIcon} alt="" />
              </div>
              <div className="plans-holder">
                <div className="plans-headers">
                  <div className="popular">
                    <div>Elite Plan</div>
                    <div
                      className="pricing"
                      data-old-price={prices[schedule].eliteOld}
                    >
                      <div className="sign">$</div>
                      <div className="amount">{prices[schedule].elite}</div>
                      <div className="period">
                        / {schedule === 'monthly' ? 'MO' : 'YEAR'}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>Premium Plan</div>
                    <div
                      className="pricing"
                      data-old-price={prices[schedule].premiumOld}
                    >
                      <div className="sign">$</div>
                      <div className="amount">{prices[schedule].premium}</div>
                      <div className="period">
                        / {schedule === 'monthly' ? 'MO' : 'YEAR'}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>Growth Plan</div>
                    <div
                      className="pricing"
                      data-old-price={prices[schedule].growthOld}
                    >
                      <div className="sign">$</div>
                      <div className="amount">{prices[schedule].growth}</div>
                      <div className="period">
                        / {schedule === 'monthly' ? 'MO' : 'YEAR'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="plans-footer">
                  <div>
                    <SiteLink to={upgradeUrl('elite_1')}>
                      <div className="plan-button popular">Choose Elite</div>
                    </SiteLink>
                  </div>
                  <div>
                    <SiteLink to={upgradeUrl('premium_1')}>
                      <div className="plan-button">Choose Premium</div>
                    </SiteLink>
                  </div>
                  <div>
                    <SiteLink to={upgradeUrl('growth_1')}>
                      <div className="plan-button">Choose Growth</div>
                    </SiteLink>
                  </div>
                </div>
                <p className="disclaimer">
                  * Plans are subject to upgrading to next tier and overages
                  when exceed volume limits.&nbsp;
                  <a
                    className="learn-more"
                    rel="noreferrer"
                    href="https://support.hellobar.com/support/solutions/articles/44001406917-hello-bar-overage-fee-breakdown"
                    target="_blank"
                  >
                    Learn More.
                  </a>
                </p>
                <div className="plans-content">
                  <div className="plans-row empty">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="plans-row">
                    <div className="bolder">500,000 views / month</div>
                    <div className="bold">150,000 views / month</div>
                    <div>50,000 views / month</div>
                  </div>
                  <div className="plans-row">
                    <div>Unlimited popups</div>
                    <div>Unlimited popups</div>
                    <div>Unlimited popups</div>
                  </div>
                  <div className="plans-row">
                    <div>Can remove HB logo</div>
                    <div>Can remove HB logo</div>
                    <div>Can remove HB logo</div>
                  </div>
                  <div className="plans-row">
                    <div className="bold">Premium design features</div>
                    <div className="bold">Premium design features</div>
                    <div>Basic design features</div>
                  </div>
                  <div className="plans-row">
                    <div className="bold">Premium targeting</div>
                    <div className="bold">Premium targeting</div>
                    <div>Advanced targeting</div>
                  </div>
                  <div className="plans-row">
                    <div>Unlimited A/B testing</div>
                    <div>Unlimited A/B testing</div>
                    <div>Unlimited A/B testing</div>
                  </div>
                  <div className="plans-row">
                    <div className="bold">Premium integrations</div>
                    <div className="bold">Premium integrations</div>
                    <div>Advanced integrations</div>
                  </div>
                  <div className="plans-row">
                    <div>Send popup events to GA</div>
                    <div>Send popup events to GA</div>
                    <div>Send popup events to GA</div>
                  </div>
                  <div className="plans-row">
                    <div>Premium customer support</div>
                    <div>Premium customer support</div>
                    <div className="off">Premium customer support</div>
                  </div>
                  <div className="plans-row empty">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                {/* <div className="plans-headers starter-plus">
                  <div className="plans-footer starter-plus">
                    Need a smaller plan? Get 5,000 views/month and all features
                    for {'  '}
                    <div className="pricing">
                      <div className="sign">$</div>
                      <div className="amount">
                        {prices[schedule].starterPlus}
                      </div>
                      <div className="period">
                        / {schedule === 'monthly' ? 'MO' : 'YEAR'}
                      </div>
                    </div>
                    <SiteLink to={upgradeUrl('starter_plus')}>
                      <div className="plan-button">Choose Starter Plus</div>
                    </SiteLink>
                  </div>
                </div> */}
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default withEditorPageTracking(UpgradeDialog, 'UpgradeDialog');
