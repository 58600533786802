import React, { useContext } from 'react';
import Header from '../components/interstitial/Header';
import {
  DashLink,
  SupportLink,
  Container,
  OuterWrapper,
  CardWrapper,
  InterstitialCard,
  Footer,
} from '../components/interstitial/styled';
import { EditorContext } from '../../../utils/contexts';
import { useHistory, useRouteMatch } from 'react-router-dom';

const TypeSelection = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { dispatch } = useContext(EditorContext);
  const goal = match.params.goalName;
  const goalName = () => {
    switch (goal) {
      case 'email':
        return 'Grow Email List';
      case 'traffic':
        return 'Target a URL';
      case 'social':
        return 'Social Traffic';
      case 'announcement':
        return 'Announcement';
      case 'call':
        return 'Get Phone Calls';
      case 'webform':
        return 'Make Client Lead Form';
      default:
        return 'Grow Email List';
    }
  };
  const takeoverGoals = ['email'];
  const modalGoals = ['webform'];
  const onGoalSelection = (type) => {
    dispatch({
      type: 'update_goal',
      payload: goal,
    });
    dispatch({ type: 'update_type', payload: type });
    history.push(
      `/sites/${match.params.siteId}/popups/${match.params.popupId}/design`
    );
  };

  return (
    <div>
      <Header text="CHOOSE LAYOUT TYPE" />
      <Container>
        <p>
          Since your goal is {goalName()}, we recommend you use a{' '}
          <b>
            {takeoverGoals.includes(goal)
              ? 'Page Takeover'
              : modalGoals.includes(goal)
              ? 'Modal'
              : 'Hello Bar'}
          </b>
          .
        </p>
        <p>
          {takeoverGoals.includes(goal)
            ? 'Page takeovers are the most successful at collecting emails.'
            : modalGoals.includes(goal)
            ? 'Modals are the best way to collect answers.'
            : `Hello Bars can remain visible across every page of your site and will not disrupt your visitor's experience.`}
        </p>
        <OuterWrapper>
          <CardWrapper>
            {goal !== 'webform' && (
              <InterstitialCard
                name="Bar"
                highlight={goal !== 'email' && 'Recommended'}
                title="Hello Bar"
                onClick={onGoalSelection}
              >
                A customizable bar at the top or bottom of your site.
              </InterstitialCard>
            )}
            {goal !== 'call' && (
              <>
                <InterstitialCard
                  name="Modal"
                  highlight={goal === 'webform' && 'Recommended'}
                  title="Pop-up Modal"
                  onClick={onGoalSelection}
                >
                  A pop up window in the center of your site.
                </InterstitialCard>
                <InterstitialCard
                  name="Inline"
                  highlight={'NEW'}
                  title="Hello Bar Inline"
                  onClick={onGoalSelection}
                >
                  A pop up as embedded inline element of your site.
                </InterstitialCard>
                {goal !== 'webform' && (
                  <InterstitialCard
                    name="Slider"
                    title="Slider"
                    onClick={onGoalSelection}
                  >
                    A small window that slides out of the right or left side.
                  </InterstitialCard>
                )}
              </>
            )}
          </CardWrapper>
          {goal !== 'call' && (
            <CardWrapper>
              <InterstitialCard
                name="Takeover"
                highlight={goal === 'email' && 'Recommended'}
                title="Page Takeover"
                onClick={onGoalSelection}
              >
                A full page pop up that your visitors can't miss.
              </InterstitialCard>
              {goal !== 'webform' && (
                <InterstitialCard
                  name="Alert"
                  title="Alert Bell"
                  onClick={onGoalSelection}
                >
                  A small floating button that expands when clicked on.
                </InterstitialCard>
              )}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://subscribers.com/?utm_source=hellobar&utm_medium=interstitial_screen"
              >
                <InterstitialCard
                  name="Notification"
                  title="Push Notification"
                  onClick={() => {}}
                >
                  Notify your users with Push Notifications.
                </InterstitialCard>
              </a>
            </CardWrapper>
          )}
        </OuterWrapper>
        <Footer>
          <SupportLink onClick={(e) => history.goBack()}>
            <i className="icon-back2" /> Choose a different goal
          </SupportLink>
          <DashLink href={`/sites/${match.params.siteId}/summary`}>
            I’ll do this later, take me to my dashboard
          </DashLink>
        </Footer>
      </Container>
    </div>
  );
};

export default TypeSelection;
